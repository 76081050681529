import React, { useState, useRef, useEffect } from 'react';
import './TextDisplay.css'; // Import your CSS file

const TextDisplay = () => {
  const [selectedButtonIndex, setSelectedButtonIndex] = useState(0);
  const buttonsContainerRef = useRef(null);

  // Array of buttons, each with button text, paragraphs, and lists
  const buttons = [
    {
      buttonText: 'Amazon',
      paragraphs: ['Incoming SDE Intern @ Amazon', 'May 2025 - Aug 2025'],
      lists: [['Seattle, WA', 
               'Summer 2025', 
               'Team TBD']],
    },
    {
      buttonText: 'Fleet',
      paragraphs: ['Co-founder & Software Engineer @ Fleet', 'Jan 2024 - Present'],
      lists: [['Provide businesses with aggregated social media data analytics for short-form content creators across 6 performance metrics.', 
               'Deliver total account and video metrics for over 30,000 posts to provide detailed data for likes, comments, views, and shares.', 
               'Developed web app end-to-end, implementing frontend, database infrastructure, backend, graphs, modals, and more.']],
    },
    {
      buttonText: 'Caltech',
      paragraphs: ['Research Software Engineer @ Caltech IPAC', 'Dec 2021 - Present'],
      lists: [['Microlensing Project: Code that forecasts microlensing events based on astrometry of ultracool dwarfs and ZTF AVRO data.', 
                'Substellar Project: Simulate populations of substellar objects to predict functional mass distribution within 20 parsecs.', 
                'Substellar Project: Co-authored papers published to The Astrophysical Journal and released software to Zenodo repository.']],
    },
    {
      buttonText: 'UCode',
      paragraphs: ['Software Engineer Intern @ UCode Programming Academy', 'Aug 2020 - Jan 2023'],
      lists: [['Spearheaded UI/UX overhaul of learning management system (LMS), increasing satisfaction ratings from UCode students. ', 
                'Designed and instructed intermediate level Python curriculum covering lists, classes, dictionaries, objects, and more.', 
                'Coded educational tools in Roblox using Lua to teach game development, networking, databases, and more.']],
    },
  ];

  // When adding new employment, just copy paste one entire bracket section with the buttonText
  // paragraphs, etc, and fill in each section accordingly with new information. 
  

  const handleButtonClick = (index) => {
    setSelectedButtonIndex(index);
  };

  // Function to update the scrollbar position based on the selected button
  useEffect(() => {
    const buttonsContainer = buttonsContainerRef.current;
    const selectedButton = buttonsContainer.querySelector(
      `.custom-button:nth-child(${selectedButtonIndex + 1})`
    );
    if (selectedButton) {
      buttonsContainer.scrollTo({
        top: selectedButton.offsetTop,
        behavior: 'smooth',
      });
    }
  }, [selectedButtonIndex]);

  return (
    <div style={{ display: 'flex', marginLeft: 455 }}>
      <div
        ref={buttonsContainerRef}
        style={{
          display: 'flex',
          flexDirection: 'column',
          paddingRight: '10px', // Add some spacing to the right for the scrollbar
          overflowY: 'scroll', // Add scrollbar to the container
          scrollbarWidth: 'thin', // Customize the scrollbar width
          scrollbarColor: '#00FF89 #111', // Customize the scrollbar colors
        }}
      >
        {buttons.map((button, index) => (
          <button
            key={index}
            onClick={() => handleButtonClick(index)}
            className={`custom-button ${
              selectedButtonIndex === index ? 'active' : ''
            }`} // Add the class name for styling
          >
            {button.buttonText} {/* Display the button text */}
          </button>
        ))}
      </div>
      <div className="paragraphs-and-lists-container">
        <p className="selected-paragraph">
          {buttons[selectedButtonIndex].paragraphs[0]} {}
        </p>
        <p className="selected-paragraph2">
          {buttons[selectedButtonIndex].paragraphs[1]} {}
        </p>
        <ul className="selected-list">
          {buttons[selectedButtonIndex].lists[0].map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default TextDisplay;